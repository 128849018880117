















































































section {
  main {
    width: 1200px;
    margin: 0 auto;

    > div {
      &:nth-child(1) {
        padding: 80px 0;
        display: flex;
        justify-content: space-between;

        > img {
          width: 570px;
          height: 370px;
        }

        > div {
          flex: 1 1 100%;
          padding: 50px 80px;
          position: relative;

          > h3 {
            font-size: 30px;
            font-weight: normal;
            color: #000;
            line-height: 40px;
            margin-bottom: 40px;
          }

          > span {
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: #333;
            margin-left: 5px;
          }

          > a {
            width: 160px;
            height: 45px;
            line-height: 45px;
            position: absolute;
            right: 0;
            bottom: 0;
            color: #fff;
            font-size: 15px;
            font-style: normal;
            text-align: center;
            background-color: #d9261d;

            &:hover {
              color: #333;
            }
          }
        }
      }

      &:nth-child(2){
        padding: 50px 0;
        border-top: 1px solid #ececec;
        > div{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            text-align: center;
            padding: 40px 0;

            &:hover {
              background-color: #f5f5f5;
            }

            > h3 {
              font-size: 30px;
              font-weight: normal;
              color: #000;
              line-height: 40px;
              margin-bottom: 40px;
            }

            > div{
              white-space: pre-line;
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  section {
    main{
      width: 100% !important;
      >div:nth-child(1){
        padding: 80px 15px;
        >img{
          display: none;
        }
      }
      >div:nth-child(2){
        padding: 0 7.5px;
        h3 {
          font-size: 20px !important;
          line-height: 24px !important;
        }
      }
    }
  }
}
